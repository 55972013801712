:root {
  --nav-height: 2rem;
  /* color palette */
  --main-color: rgb(41, 204, 229);
  --main-color-green: #85ff9e;
  --main-color-blue: #1c3144;
  --main-color-yellow: #ffba08;
  --main-color-orange: #ff5714;
  --modal-bg-color: #dbf8e1; /*   #dbf8e1  */
  --three-d-font: "Rampart One";
  --azaret-font: "Azeret Mono";
  --font-1: "Anton";
  --font-2: "Acme";
  --font-3: "Roboto";
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

html {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 22px;
  }
}
body {
  margin: 0;
  min-height: 99.9vh;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(44, 205, 229, 0.9920343137254902) 131%
  );
}
/* @media screen and (max-width: 729px) {
  body {
    min-height: 97vh;
  }
} */
