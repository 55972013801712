.folder-settings-main-container {
  position: absolute;
  width: 350px;
  max-width: 95%;
  top: 50%;
  left: 50%;
  height: 600px;
  max-height: 90vh;
  right: 0.4rem;
  border-radius: 0.7rem;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0 17px 50px 0 rgb(0 0 0 / 19%), 0 12px 15px 0 rgb(0 0 0 / 24%);
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-family: "Acme";
}
.folder-settings-modal-title {
  /* margin-left: 50%;
  transform: translate(-50%); */
  font-family: "Acme";
  font-size: 20px;
  color: rgba(0, 0, 0, 0.611);
}
.radio-container {
  margin-top: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.179);
}
#folderNameInput {
  resize: none;
  outline: none;
  width: 70%;
  padding: 0.5rem;
  border-radius: 0.4rem;
  font-family: "Acme";
  font-size: 0.8rem;
}
.folder-modal-settings-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.change-folder-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deleteFolderAttention {
  color: rgba(253, 0, 0, 0.631);
  font-size: 15px;
  font-style: italic;
}
/* .selectWrapper {
  max-height: 25vh;
  overflow: auto;
} */
