.modal-main-container {
  position: fixed;
  width: 80%;
  max-width: 1100px;
  top: 50%;
  height: 90%;
  left: 50%;
  border-radius: 0.7rem;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0 17px 50px 0 rgb(0 0 0 / 19%), 0 12px 15px 0 rgb(0 0 0 / 24%);
  background-color: var(--modal-bg-color);
  overflow: auto;
}
@media (max-width: 576px) {
  .modal-main-container {
    width: 95%;
  }
}
@media (min-width: 576px) and (max-width: 1139px) {
  .modal-main-container {
    width: 90%;
  }
}
@media (min-width: 1139px) and (max-width: 1209px) {
  .modal-main-container {
    width: 85%;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.main-content-modal {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.modal-header {
  height: 3rem;
  display: flex;
  justify-content: space-between;
}
.xbtn {
  font-size: 1.2rem;
}

.xbtn-wrapper {
  cursor: pointer;
  border: none;
  background-color: var(--modal-bg-color);
  border-radius: 1rem;
  height: 2rem;
  width: 2rem;
}
.xbtn-wrapper:hover {
  background-color: #c1dbc7;
}

.modal-title-input {
  width: 90%;
  background-color: var(--modal-bg-color);
  outline-color: #99caa3;
  border: none;
  font-family: "Acme", var(--font-3), "Azeret Mono", "Gill Sans", "Gill Sans MT",
    "Calibri", "Trebuchet MS", sans-serif;
  font-size: 0.9rem;
  /* color: var(--main-color-blue); */
  min-height: 1rem;
  overflow-wrap: break-word;
  resize: none;
  margin: 0.3rem;
}

.modal-after-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.modal-comments-input {
  /* width: 90%; */
  background-color: var(--modal-bg-color);
  outline-color: #99caa3;
  border: none;
  font-family: var(--font-3), "Azeret Mono", "Gill Sans", "Gill Sans MT",
    "Calibri", "Trebuchet MS", sans-serif;
  font-weight: 600;
  color: var(--main-color-blue);
  overflow-wrap: break-word;
  resize: none;
  margin: 0.3rem;
}

.grab-the-snippet-button {
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-family: "Acme";
  font-size: 0.8rem;
  margin-top: 1rem;
  border: none;
  background-color: rgb(35, 182, 204);
  color: white;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
}

.grab-the-snippet-button:hover {
  background-color: var(--main-color);
  cursor: grab;
}
.grab-the-snippet-button:active {
  cursor: grabbing;
}

.add-Modal-editor-inputs-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.add-Modal-inputs-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 0.2rem;
  width: 20%;
}

.add-snippet-textarea {
  font-size: 0.7rem;
  font-family: "Acme";
  background-color: white;
  border-radius: 0.2rem;
  border: none;
  outline-color: #99caa3;
  width: 100%;
  height: 45%;
  padding: 0.5rem;
  overflow-wrap: break-word;
  resize: none;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
}

.div-textarea {
  font-size: 0.7rem;
  font-family: "Acme";
  background-color: white;
  border-radius: 0.2rem;
  border: none;
  outline-color: #99caa3;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  overflow-wrap: break-word;
  overflow: auto;
  resize: none;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
}

.add-snippet-query-input {
  font-family: "Acme";
  background-color: white;
  border-radius: 0.2rem;
  outline-color: #99caa3;
  border: 1px solid var(--main-color-blue);
  width: 100%;
  padding: 0.5rem;
  margin-top: 2rem;
  border: none;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
}
.editor-col {
  width: 80%;
  /* display: flex;
  flex-direction: column; */
}
.clear-editor-btn {
  padding: 0.5rem;
  margin-top: 2rem;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  font-family: "Acme";
  border: none;
  background-color: rgb(204, 35, 66);
  color: white;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  cursor: pointer;
}
.clear-editor-btn:hover {
  background-color: rgb(175, 31, 58);
}

.save-editor-btn {
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-family: "Acme";
  margin-top: 2rem;
  border: none;
  font-size: 0.8rem;
  /* background-color: rgb(35, 182, 204); */
  background-color: var(--main-color-green);
  color: var(--main-color-blue);
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  cursor: pointer;
}
.save-editor-btn:hover {
  background-color: #69fa86;
}
/* .save-editor-disabled {
  padding: 0.6rem;
  border-radius: 0.3rem;
  font-family: "Acme";
  margin-top: 2rem;
  border: none;
  background-color: grey;
  color: white;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  cursor: pointer;
} */

.w-80 {
  width: 80%;
}
.w-20 {
  width: 20%;
}
.textarea-node {
  height: 45%;
}
@media (max-width: 600px) {
  .textarea-node {
    width: 45%;
    height: 5rem;
  }
  .w-80 {
    width: 100%;
  }
  .w-20 {
    width: 20%;
  }
  .editor-col {
    width: 100%;
  }
  .add-Modal-editor-inputs-wrapper {
    flex-direction: column;
  }
  .add-Modal-inputs-wrapper {
    margin-top: 1rem;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
  .add-snippet-query-input {
    width: fit-content;
    overflow: auto;
    margin-right: 0.5rem;
  }
  /* .add-snippet-textarea {
    height: 4rem;
    width: 50%;
    margin-right: 0.5rem;
  } */
  .save-editor-btn {
    margin-right: 0.3rem;
    font-size: 0.7rem;
    margin-top: auto;
    height: 2.5rem;
  }

  .clear-editor-btn {
    margin-left: 0.5rem;
    font-size: 0.7rem;
    margin-top: auto;
    height: 2.5rem;
  }
  .grab-the-snippet-button {
    font-size: 0.7rem;
    margin-top: auto;
    height: 2.5rem;
  }
  .clear-editor-btn {
    margin-right: 0.3rem;
  }
}

/* DELETE MODAL  */

.small-delete-modal {
  position: fixed;
  height: 150px;
  width: 300px;
  background-color: white;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 1000;
  border-radius: 0.7rem;
  opacity: 100;
  box-shadow: 0 17px 50px 0 rgb(0 0 0 / 19%), 0 12px 15px 0 rgb(0 0 0 / 24%);
  padding: 0.5rem;
  font-family: "Acme";
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.delete-modal-overlay {
  position: absolute;
  top: -1px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.hiddenLink {
  text-decoration: none;
  text-align: center;
  font: "Acme";
  color: black;
}
.hiddenLink :hover {
  color: rgb(138, 138, 138) !important;
}
