.lph1 {
  cursor: pointer;
  font-family: Rampart One;
  position: relative;
}
.lph1::before {
  content: "";
  position: absolute;
  background-color: var(--main-color-green);
  height: 2px;
  bottom: 0;
  left: 0;
  width: 0%;
  transition: 250ms linear;
}

.lph1:hover::before {
  width: 100%;
}

.login-page-container {
  margin-top: 1rem;
  box-shadow: 0 17px 50px 0 rgb(0 0 0 / 19%), 0 12px 15px 0 rgb(0 0 0 / 24%);
  background-color: white;
  height: 80%;
  width: 570px;
  border-radius: 10px;
  padding: 1.5rem;
  font-family: var(--font-2);
}

.login-page-wrapper {
  display: flex;
  flex-direction: column;
}

.login-1st-span {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  color: rgb(141, 184, 247);
}

.login-page-button {
  width: 100%;
  background-color: #6fcc81;
  color: white;
  font-family: var(--font-2);
  font-size: 16px;
  border-radius: 0.6em;
  padding: 0.7em;
  cursor: pointer;
  border: none;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
}

.login-page-button:hover {
  background-color: #62e17b;
}

.login-continue-with-provider {
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  background-color: white;
  cursor: pointer;
  padding: 1em;
  font-size: 16px;
  border-radius: 0.5em;
  margin-top: 1em;
  margin-bottom: 0.5em;
  border: none;
  font-family: var(--font-3);
}
.login-continue-with-provider:hover {
  background-color: rgb(243, 243, 243);
}

.login-link {
  color: rgb(159, 190, 236);
  cursor: pointer;
  margin-top: 1.5rem;
}
.login-link:hover {
  color: rgb(111, 163, 242);
  text-decoration: underline;
}

.login-icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
/* signup */

.circle-loader {
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}

.checkmark {
  /* display: none; */
}
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 1em;
  width: 0.5em;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: "";
  left: 0.2em;
  top: 0.9em;
  position: absolute;
}
.disabled {
  cursor: not-allowed !important;
  background-color: rgba(0, 0, 0, 0.276) !important;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.5em;
    opacity: 1;
  }
  40% {
    height: 1em;
    width: 0.5em;
    opacity: 1;
  }
  100% {
    height: 1em;
    width: 0.5em;
    opacity: 1;
  }
}
