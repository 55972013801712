.home-main-container {
  margin-top: 0.5rem;

  font-family: "Acme";
}
.home-functionality-wrapper {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.179);
  padding: 0.5rem;
  align-items: center;
  position: relative;
}

.addSnippetBtn {
  background-color: var(--main-color-green);
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1rem;
  border: none;
  color: var(--main-color-blue);
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 40%) 1px 1px 5px 0;
}
.addSnippetBtn:hover {
  background-color: #69fa86;
}
.addFolderBtn {
  margin-left: 0.5rem;
  background-color: var(--main-color-orange);
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1rem;
  border: none;
  color: var(--main-color-blue);
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 40%) 1px 1px 5px 0;
}
.addFolderBtn:hover {
  background-color: var(--main-color-yellow);
}

.home-cards-container {
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.folder-input-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  background-color: var(--main-color-orange);
  padding: 0.5rem;
  border-radius: 1rem;
  border: none;
  color: var(--main-color-blue);
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 40%) 1px 1px 5px 0;
  position: absolute;
  left: 9rem;
  top: 0;
  z-index: 10;
}
@media (max-width: 319px) {
  .folder-input-wrapper {
    left: 6rem;
  }
  .home-functionality-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 560px) {
  .home-functionality-wrapper {
    flex-direction: column;
  }
}
#folderInput {
  resize: none;
  outline: none;
  border: none;
  padding: 0.5rem;
  border-radius: 0.4rem;
  font-family: "Acme";
  font-size: 0.8rem;
}
.folder-inputs-wrapper {
  display: flex;
  margin-top: 0.2rem;
  justify-content: space-around;
}

.folder-btn1 {
  padding: 0.4rem;
  border-radius: 0.3rem;
  font-family: "Acme";
  border: none;
  background-color: rgb(35, 182, 204);
  color: white;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  cursor: pointer;
}
.folder-btn1 {
  padding: 0.2rem;
  border-radius: 0.3rem;
  font-family: "Acme";
  font-size: 0.7rem;
  border: none;
  background-color: var(--main-color-green);
  color: var(--main-color-blue);
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  cursor: pointer;
  width: 30%;
}
.folder-btn1:hover {
  background-color: #69fa86;
}

.folder-btn2 {
  padding: 0.2rem;
  border-radius: 0.3rem;
  font-family: "Acme";
  border: none;
  background-color: rgb(204, 35, 66);
  color: var(--main-color-blue);
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  cursor: pointer;
  width: 30%;
  font-size: 0.7rem;
  font-weight: 600;
}
.home-folder-settings-wrapper {
  margin-left: 2rem;
  height: 2rem;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  border-left: 2px solid var(--main-color-green);
}

.home-buttons-folder-wrapper {
  display: flex;
  flex-direction: row;
}
.home-folder-btn {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: var(--main-color-yellow);
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1rem;
  border: none;
  color: var(--main-color-blue);
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 40%) 1px 1px 5px 0;
}
.home-folder-btn:hover {
  background-color: #ffaf03;
}

.home-buttons-folder-wrapper {
  display: flex;
  flex-direction: row;
}

@media (max-width: 700px) {
  .home-buttons-folder-wrapper {
    display: flex;
    flex-direction: column;
  }

  .home-folder-settings-wrapper {
    border: none;
    margin-top: 1rem;
    margin-left: 0rem;
    padding-left: 0rem;
  }
  .home-folder-btn {
    margin-left: 0rem;
  }
}

@media (max-width: 600px) {
  .home-cards-container {
    justify-content: center;
  }
}
.imgBord {
  border: 2px solid white;
  border-radius: 15px;
}

.goToTopBtn {
  height: 2.5rem;
  width: 2.5rem;
  background-color: grey;
  border-radius: 50%;
  position: fixed;
  top: 90%;
  left: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  z-index: 100;
  cursor: pointer;
}
.goToTopBtn:hover {
  opacity: 1;
}
.arrow {
  color: white;
  font-size: 1.8rem;
}
.parentLink {
  margin-left: 0.5rem;
  border-radius: 25%;
  padding: 0.2rem;
  background-color: rgb(255, 255, 255, 1);
  text-decoration: none;
}
.parentLink:hover {
  text-decoration: underline;
}
/* search bar */
.search-box {
  margin-left: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;

  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: 2px solid white;

  border-radius: 25px;
}
.search-box:focus {
  outline: none;
}

@media (max-width: 560px) {
  .search-box {
    margin-top: 1rem;
    margin-left: 0;
  }
}
