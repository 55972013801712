.pseudoNav {
  background-color: transparent;
  box-shadow: none;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 50px;
  padding: 1rem;
  position: relative;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}
.pseudoNav[data-scrolled="true"] {
  background-color: rgb(35, 155, 173);
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.179);
}
#opening button {
  font-family: "Acme";
  width: 100%;
  max-width: 600px;
}
section h1 {
  font-weight: 400;
  text-align: justify;
}
.navbar-header-sp {
  font-family: "Rampart One";
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  margin-left: 0.5rem;
  /* opacity: 0.8; */
}

.navbar-header-sp::before {
  content: "";
  position: absolute;
  background-color: var(--main-color-green);
  height: 2px;
  bottom: 0;
  left: 0;
  width: 0%;
  transition: 250ms linear;
}

.navbar-header-sp:hover::before {
  width: 100%;
}
.navbar-header-sp:hover {
  opacity: 1;
}
.sph1 {
  margin-top: 0;
  margin-bottom: 5rem;
  font-size: 2rem;
  font-weight: 500;
  color: black;
  opacity: 0.8;
}
#opening {
  margin-top: 3rem;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}

.sp-section2 {
  margin-top: 3rem;
  color: white;
}
.jumbotron {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: transparent;
  border-radius: 0.3rem;
  font-family: "Acme";
  width: 90%;
}
/* @media (max-width: 1200px) {
  .jumbotron {
    width: 70%;
  }
} */

.fldrs1 {
  margin-bottom: auto;
  margin-top: auto;
}
.splink1 {
  color: black !important;
  opacity: 0.8;
  transition: 200ms linear;
  margin-right: 1rem;
  margin-left: 1rem;
}
.splink1:hover {
  opacity: 1;
  transform: scale(1.3);
}
footer {
  width: 100%;
  border-top: 3px solid rgb(35, 155, 173);
  padding: 1rem;
  margin-top: auto;
  text-align: center;
  color: white;
  display: flex;
  font-family: Lobster;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  flex-direction: column;
}
.qpft {
  width: 100%;
  /* position: relative;
  bottom: 0; */
  border-top: 3px solid rgb(35, 155, 173);
  padding: 1rem;
  margin-top: auto;
  text-align: center;
  color: white;
  display: flex;
  font-family: Lobster;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  flex-direction: column;
}
.spftlink {
  margin-left: 1rem;
  color: white;
  cursor: pointer;
  transition: 200ms linear;
  font-size: 1.5rem;
}
.spftlink:hover {
  transform: scale(1.3);
  color: rgb(35, 155, 173) !important;
}

.sectionOne {
  margin-top: 1rem;
  background-image: linear-gradient(
      to bottom,
      rgba(44, 205, 229, 0.7),
      rgba(0, 0, 0, 0.2)
    ),
    url("http://localhost:3000/mockup.png");
  height: 50vh;
  background-size: cover;
  background-position: center;
}
footer span {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.73);
}

@media (min-width: 1400px) {
  .jumbotron {
    width: 70%;
  }
}
@media (min-width: 1600px) {
  .jumbotron {
    width: 60%;
  }
}
@media (max-width: 540px) {
  .sp-section1 {
    margin-top: 3rem;
  }
}

@media (max-width: 767px) {
  .sph1 {
    margin-bottom: 3rem;
    font-size: 1.5rem;
  }
  .cardsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 600px) {
    .cardsWrapper {
      display: none;
    }
  }
  section,
  p {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .pseudoNav {
    justify-content: center;
  }
}
