/* ACCOUNT MODAL */
.account-modal-main-container {
  position: absolute;
  width: 350px;
  max-width: 95%;
  top: 7vh;
  height: 600px;
  max-height: 90vh;
  right: 0.4rem;
  border-radius: 0.7rem;
  /* transform: translate(-50%, -50%); */
  z-index: 1000;
  box-shadow: 0 17px 50px 0 rgb(0 0 0 / 19%), 0 12px 15px 0 rgb(0 0 0 / 24%);
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.account-modal-header {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.179);
  align-items: center;
}
.xbtn1 {
  font-size: 1.2rem;
}

.xbtn-wrapper1 {
  cursor: pointer;
  border: none;
  background-color: white;
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
  margin-left: auto;
}
.xbtn-wrapper1:hover {
  background-color: #edebeb;
}

.account-modal-account {
  margin-left: 50%;
  transform: translate(-50%);
  font-family: "Acme";
  font-size: 20px;
  color: rgba(0, 0, 0, 0.611);
}

.account-modal-info {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.179);
  padding: 0.3rem;
}
.acc-name {
  font-family: "Acme";
  font-size: 17px;
}
.acc-mail {
  font-family: "Acme";
  font-size: 17px;
  margin-top: 0.4rem;
  margin-left: 1rem;
  color: grey;
}
.acc-settings {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.179);
}

#acc-modal-editor-selector {
  border: 1px solid black;
  font-family: var(--font-2);
  font-weight: 600;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.74);
  padding: 0.5em;
  border-radius: 0.8em;
  outline: none !important;
  width: fit-content;
  margin-top: 5px;
}
#acc-editor-languages-selector:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
#acc-editor-themes-selector {
  padding: 0.5em;
  border-radius: 0.8em;
  border: 1px solid black;
  font-family: var(--font-2);
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.74);
  outline: none !important;
  margin-top: 5px;
  margin-bottom: 10px;
}
#acc-editor-themes-selector:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.acc-editor-selector-label {
  font-family: var(--font-2);
  font-style: italic;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.74);
}
.acc-modal-save-btn {
  padding: 0.6em;
  border-radius: 0.3em;
  margin-left: auto;
  margin-right: auto;
  font-family: "Acme";
  margin-top: 1em;
  margin-bottom: 1em;
  width: 80%;
  border: none;
  font-size: 18px;
  background-color: rgb(35, 182, 204);
  color: white;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  cursor: pointer;
}
.acc-modal-save-btn-disabled {
  padding: 0.6em;
  border-radius: 0.3em;
  margin-left: auto;
  margin-right: auto;
  font-family: "Acme";
  margin-top: 1em;
  margin-bottom: 1em;
  width: 80%;
  border: none;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  cursor: not-allowed;
}
.acc-modal-save-btn:hover {
  background-color: var(--main-color);
}
.acc-modal-logOut-btn {
  padding: 0.6em;
  border-radius: 0.3em;
  margin-left: auto;
  margin-right: auto;
  font-family: "Acme";
  margin-top: 3em;
  margin-bottom: 1em;
  width: 80%;
  border: none;
  font-size: 18px;
  background-color: rgb(35, 117, 204);
  color: white;
  /* box-shadow: rgb(35, 117, 204, 20%) 10px 10px 5px 0; */
  cursor: pointer;
  transition: 200ms linear;
}
.acc-modal-logOut-btn:hover {
  background-color: rgb(34, 105, 181);
  box-shadow: rgb(35, 117, 204, 20%) 10px 10px 5px 0;
}
